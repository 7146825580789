import React from 'react';
import { View, Text, Image, StyleSheet } from '@react-pdf/renderer';

import logo from './logo.png';

const styles = StyleSheet.create({
  logoContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    gap: 4,
  },
  processedBy: {
    fontSize: 10,
    lineHeight: 1.2,
    color: '#92969E',
  },
  logo: {
    width: 37,
    height: 9,
  },
});

export const PdfFooterLogo = () => (
  <View style={styles.logoContainer}>
    <Text style={styles.processedBy}>Processed by</Text>
    <Image src={logo} style={styles.logo} />
  </View>
);
