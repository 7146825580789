import React from 'react';
import { View, Text, Image } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { reportPeriodFormatter } from 'settlements/utils/periodFormatter';
import { cryptoChains } from 'common/currencies';
import { displayUtcDate } from 'common/services/date';
import { amountType } from 'payments/propTypes';

import { commonStyles as styles } from './pdfCommonStyles';
import logo from './logo.png';

const HeaderBox = ({ children }) => (
  <View
    style={{
      borderStyle: 'solid',
      borderColor: '#E9EAEC',
      borderWidth: 1,
      borderRadius: 4,
      padding: 12,
      flex: 1,
    }}
  >
    {children}
  </View>
);

export const ReportHeader = ({
  activeStoreName,
  start,
  end,
  settledOn,
  id,
  blockchain,
  toBeSettledAmount,
  toBeSettledReferenceAmount,
  referenceCurrency,
}) => {
  const period = reportPeriodFormatter(start, end);
  const settledOnDate = settledOn
    ? displayUtcDate(settledOn, 'yyyy/MM/dd')
    : 'N/A';

  return (
    <View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Text style={[styles.semibold, { fontSize: 20, lineHeight: 1.4 }]}>
          Settlement Report
        </Text>
        <Image src={logo} style={{ width: 28, height: 28 }} />
      </View>
      <Text style={{ fontSize: 12, lineHeight: 1, marginTop: 4 }}>
        {period}
      </Text>
      <View
        style={{ display: 'flex', flexDirection: 'row', gap: 8, marginTop: 20 }}
      >
        <HeaderBox>
          <Text style={{ fontSize: 12, lineHeight: 1 }}>Total paid</Text>
          <Text
            style={[
              { fontSize: 16, lineHeight: 1, marginTop: 8 },
              styles.semibold,
            ]}
          >
            {toBeSettledAmount.currency} {toBeSettledAmount.value}
          </Text>
          <Text
            style={[
              { fontSize: 12, lineHeight: 1, color: '#92969E', marginTop: 8 },
              styles.semibold,
            ]}
          >
            {referenceCurrency} {toBeSettledReferenceAmount}
          </Text>
        </HeaderBox>
        <HeaderBox>
          <View style={{ display: 'flex', flexDirection: 'row', gap: 4 }}>
            <Text style={[{ fontSize: 12, lineHeight: 1 }]}>ID:</Text>
            <Text style={[{ fontSize: 12, lineHeight: 1, color: '#7C818B' }]}>
              {id}
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 4,
              marginTop: 8,
            }}
          >
            <Text style={[{ fontSize: 12, lineHeight: 1 }]}>Settled on:</Text>
            <Text style={[{ fontSize: 12, lineHeight: 1, color: '#7C818B' }]}>
              {settledOnDate}
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 4,
              marginTop: 8,
            }}
          >
            <Text style={{ fontSize: 12, lineHeight: 1 }}>Merchant:</Text>
            {/* flex: 1 makes the text wrap to the next line */}
            <Text
              style={{ fontSize: 12, lineHeight: 1, color: '#7C818B', flex: 1 }}
            >
              {activeStoreName}
            </Text>
          </View>
        </HeaderBox>
      </View>
      {blockchain && (
        <Text
          style={{
            fontSize: 10,
            lineHeight: 1.2,
            color: '#6A6A6A',
            marginTop: 12,
          }}
        >
          This settlement was processed using the {cryptoChains[blockchain]}{' '}
          Network
        </Text>
      )}
    </View>
  );
};

ReportHeader.propTypes = {
  activeStoreName: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  settledOn: PropTypes.string,
  id: PropTypes.string.isRequired,
  blockchain: PropTypes.string,
  toBeSettledAmount: amountType,
  toBeSettledReferenceAmount: PropTypes.string,
  referenceCurrency: PropTypes.string,
};

ReportHeader.defaultProps = {
  settledOn: null,
};
