import React from 'react';
import PropTypes from 'prop-types';

import { api } from 'app/services';

import Text from 'common/components/Text';
import Icon from 'common/components/Icon';
import { DownloadReportButton } from 'settlements/components/DownloadReportButton';

import styles from './index.module.css';

const exportReport = (id) => {
  api.exportSettlementReport(id);
};

const DownloadLink = ({ id, type }) => (
  <div
    className={styles.root}
    onClick={() => exportReport(id)}
    onKeyDown={({ key }) => {
      if (key === 'Enter') exportReport(id, type);
    }}
    role="button"
    tabIndex="0"
  >
    <Text>{type.toUpperCase()}</Text>
    <Icon.Download size="medium" />
  </div>
);

const ReportLinks = ({ settlementId }) => (
  <>
    <DownloadReportButton id={settlementId} />
    <DownloadLink id={settlementId} type="xls" />
  </>
);

ReportLinks.propTypes = {
  settlementId: PropTypes.string.isRequired,
};

export default ReportLinks;
