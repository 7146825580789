import { get, snakeCase } from 'lodash';
import normalize from 'json-api-normalizer';

import getAPIUrl from 'common/services/apiResolver';
import constants, {
  killSwitchStatus,
  killSwitchStatusText,
} from 'common/constants';
import { displayUtcDate } from 'common/services/date';
import formUtils from 'common/formUtils';

const clearItem = (key) => localStorage.removeItem(key);
const getItem = (key) => localStorage.getItem(key);
const storeItem = (key, value) => localStorage.setItem(key, value);
const getAccessToken = () => getItem('accessToken');
const getRefreshToken = () => getItem('refreshToken');
const apiToken = () =>
  getAccessToken() || getItem('signInToken') || getItem('identificationToken');

const shouldUseDevApi =
  process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';

const apiRoot = shouldUseDevApi
  ? process.env.REACT_APP_DEV_API_URL
  : getAPIUrl();

const UO_API_ROOT = shouldUseDevApi
  ? 'https://identity-dev.xmoney.com/api'
  : getAPIUrl(null, true);

const defaultFetchOptions = () => ({
  headers: {
    'Content-Type': 'application/vnd.api+json',
    Authorization: `Bearer ${apiToken()}`,
  },
});

const withFetchOptions = (version) => ({
  headers: {
    'Content-Type': 'application/vnd.api+json',
    Accept: `application/vnd.app.v${version}+json`,
    Authorization: `Bearer ${apiToken()}`,
  },
});

/**
 * Creates a hidden link and clicks it so we can use the browser
 * native behaviour to download the received file
 */
const triggerDownload = (blob, { fileName, type }) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.${type}`);
  document.body.appendChild(link);

  link.click();
  link.remove();
};

const handleBlobContent = (response) =>
  response.blob().then((blob) => ({ blob, response, json: null }));

const handleJsonContent = (response) =>
  response.json().then((json) => ({ json, response, blob: null }));

const handleBlobResponse = (blob, response, blobOptions) => {
  const { ok, statusText, status } = response;
  if (!ok) {
    return { error: `${status} - ${statusText}` };
  }

  triggerDownload(blob, blobOptions);

  return { response: response.statusText };
};

const handleJsonResponse = (
  json,
  response,
  endpoint,
  camelizeKeys,
  errorPath
) => {
  const normalized = normalize(json, { endpoint, camelizeKeys });
  if (response.ok) return { response: normalized };

  const errorDetail = get(json, errorPath || 'errors[0].detail');
  if (
    response.status === 401 &&
    (errorDetail === constants.invalid_token ||
      errorDetail === constants.signature_error)
  )
    return Promise.reject(errorDetail);
  if (response.status === 404) {
    return { error: constants.NOT_FOUND };
  }
  if (response.status === killSwitchStatus) {
    return { error: { message: killSwitchStatusText } };
  }

  return { error: errorDetail || 'Something bad happened' };
};

const fileContentTypes = [
  'text/csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const callApi = (
  endpoint,
  fetchOptions,
  camelizeKeys = true,
  blobOptions,
  errorPath
) => {
  const fullUrl = `${apiRoot}/${endpoint}`;

  return fetch(fullUrl, { ...defaultFetchOptions(), ...fetchOptions })
    .then((response) => {
      const contentType = response.headers.get('content-type');
      if (fileContentTypes.includes(contentType)) {
        return handleBlobContent(response);
      }
      return handleJsonContent(response);
    })
    .then(({ json, response, blob }) => {
      if (blob) return handleBlobResponse(blob, response, blobOptions);

      return handleJsonResponse(
        json,
        response,
        endpoint,
        camelizeKeys,
        errorPath
      );
    })
    .catch((error) => ({ error }));
};

const callUOApi = (endpoint, fetchOptions) => {
  const fullUrl = `${UO_API_ROOT}/${endpoint}`;

  return fetch(fullUrl, {
    headers: {
      'Content-Type': 'application/json',
    },
    ...fetchOptions,
  })
    .then(async (response) => {
      let responseData;

      try {
        responseData = await response.json();
      } catch (error) {
        responseData = null;
      }

      if (!response.ok) {
        return Promise.reject({
          message: responseData.message,
          error: responseData.error,
          statusCode: responseData.statusCode,
        });
      }

      return responseData;
    })
    .then((json) => ({ response: json }))
    .catch((error) => ({ error }));
};

const fetchFeatures = () => callApi('flags');

const fetchPermissions = () => callApi('permissions');

const fetchAllCountries = () => callApi('countries');
const fetchBankCountries = () => callApi('countries/supported_bank_countries');
const fetchBuyerCountries = () => callApi('countries/buyer');
const fetchMerchantCountries = () => callApi('countries/merchant');

const updateIntegrationType = ({ uuid, integrationType }) =>
  callApi(`settings/store/${uuid}`, {
    method: 'PATCH',
    body: JSON.stringify({
      data: {
        id: uuid,
        type: 'stores',
        attributes: {
          store: {
            integration_type: integrationType,
            uuid,
          },
        },
      },
    }),
  });

const fetchSectors = () => callApi('sectors');
const filter = (field, value) => `filter[${field}]=${value}`;

const fetchWebhooks = ({ size, page }) => {
  const pagination = `page[size]=${size}&page[page]=${page}`;

  return callApi(`webhooks?${pagination}`);
};

const fetchOrders = ({
  dateRange,
  page,
  pageSize,
  search,
  sort: { id: sortField, sortAscending },
  status,
}) => {
  const ordersQuery = 'orders?include=payment';
  const pagination = `page[size]=${pageSize}&page[page]=${page}`;
  const filterDateRange = filter('date_range', dateRange);
  const filterSearch = filter('search', search || '');
  const filterStatus = filter('status', status);
  const sortDirection = sortAscending ? '' : '-';
  const sort = `sort=${sortDirection}${snakeCase(sortField)}`;

  const fullQuery = [
    ordersQuery,
    pagination,
    filterDateRange,
    filterSearch,
    filterStatus,
    sort,
  ].join('&');

  return callApi(fullQuery);
};

const fetchInvoicePage = (id) => callApi(`invoices/${id}`);

const fetchInvoices = ({
  dateRange,
  page,
  pageSize,
  search,
  sort: { id: sortField, sortAscending },
  status,
}) => {
  const paginatedInvoices = `invoices?page[size]=${pageSize}&page[page]=${page}`;
  const filterDateRange = filter('date_range', dateRange);
  const filterSearch = filter('search', search || '');
  const filterStatus = filter('status', status);
  const sortDirection = sortAscending ? '' : '-';
  const sort = `sort=${sortDirection}${snakeCase(sortField)}`;

  const fullQuery = [
    paginatedInvoices,
    filterDateRange,
    filterSearch,
    filterStatus,
    sort,
  ].join('&');

  return callApi(fullQuery);
};

const cancelInvoice = (invoiceId) =>
  callApi(`invoices/${invoiceId}/cancel`, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        id: invoiceId,
        type: 'invoices',
      },
    }),
  });

const getOrdersReportFileName = () => {
  const nowIso = new Date().toISOString();
  const timestamp = displayUtcDate(nowIso, 'yyyy_MM_dd_HHmmss');

  return `xMoney_report_${timestamp}`;
};

const exportOrdersToCsv = ({ search, status, dateRange }) =>
  callApi(
    'orders/exports',
    {
      method: 'POST',
      body: JSON.stringify({
        data: {
          type: 'export',
          attributes: {
            search,
            status,
            date_range: dateRange,
          },
        },
      }),
    },
    true,
    {
      fileName: getOrdersReportFileName(),
      type: 'csv',
    }
  );

const exportSettlementReport = (id) =>
  callApi(
    `settlements/${id}/export`,
    {
      method: 'POST',
      body: JSON.stringify({
        data: {
          type: 'export',
          attributes: {},
        },
      }),
    },
    true,
    {
      fileName: 'statement_report',
      type: 'xlsx',
    }
  );

const fetchOrderPage = (id) => callApi(`orders/${id}?include=payment`);

const fetchAccountProfile = () => callApi('settings/account');

const fetchStorePage = (id) => callApi(`settings/store/${id}`);

const fetchOrganizations = () => callApi('organizations');

const fecthOrganizationStores = (organizationUUID) =>
  callApi(`merchants/${organizationUUID}/stores`);

const signUp = ({
  firstName,
  lastName,
  sendNewsletter,
  captchaToken,
  email,
  password,
}) =>
  callApi('sign_up', {
    ...withFetchOptions(3),
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'account',
        attributes: {
          email,
          password,
          first_name: firstName,
          last_name: lastName,
          captcha_token: captchaToken,
          send_newsletter: formUtils.checkBoxValue(sendNewsletter),
        },
      },
    }),
  });

const signIn = (email, password) =>
  callApi('session', {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'session',
        attributes: { email, password },
      },
    }),
  });

const submitNewInvoice = ({
  amount,
  billingAddress,
  city,
  country,
  currency,
  customerEmail,
  customerName,
  description,
  postCode,
  state,
  sendIssuedEmail,
  errorPath,
}) =>
  callApi(
    'invoices',
    {
      method: 'POST',
      body: JSON.stringify({
        data: {
          type: 'invoices',
          attributes: {
            customer: {
              email: customerEmail,
              name: customerName,
              billing_address: billingAddress,
              city,
              post_code: postCode,
              state,
              country: country.value,
            },
            fiat_amount: amount,
            fiat_currency: currency,
            send_issued_email: formUtils.checkBoxValue(sendIssuedEmail),
            description,
          },
        },
      }),
    },
    true,
    {},
    errorPath
  );

const twoFactorConfirmation = (authenticatorCode) =>
  callApi('multi_factor_auth/tfa_confirmation', {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'tfa_confirmation',
        attributes: { code: authenticatorCode },
      },
    }),
  });

const signOut = () => callApi('session', { method: 'DELETE' });

const sendForgottenPasswordEmail = ({ email, captchaToken }) =>
  callApi('forgot_password', {
    ...withFetchOptions(2),
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'forgot_password',
        attributes: { email, captcha_token: captchaToken },
      },
    }),
  });

const resetPassword = (newPassword, resetCode) =>
  callApi('set_password', {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'set_password',
        attributes: { reset_code: resetCode, new_password: newPassword },
      },
    }),
  });

const fetchOrderActivities = (orderId) =>
  callApi(`orders/${orderId}/activities`);

const fetchBusinessDetails = () => callApi('onboarding/business_details');

const submitBusinessDetails = (
  {
    businessDescription,
    bankAccountNumber,
    bankCountry,
    cryptoWalletAddress,
    iban,
    routingNumber,
    sector,
    sortCode,
    swiftCode,
  },
  storeId
) =>
  callApi('onboarding/business_details', {
    method: 'PATCH',
    body: JSON.stringify({
      data: {
        id: storeId,
        type: 'business_details',
        attributes: {
          business_description: businessDescription,
          bank_country: bankCountry,
          bank_account_number: bankAccountNumber,
          crypto_wallet_address: cryptoWalletAddress,
          iban,
          routing_number: routingNumber,
          sector_id: sector,
          sort_code: sortCode,
          swift_code: swiftCode,
        },
      },
    }),
  });

const fetchKybDocuments = () => callApi('onboarding/kyb_documents');

const createKybDocument = ({ name, label, url }) =>
  callApi('onboarding/kyb_documents', {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'kyb_document',
        attributes: {
          name,
          label,
          url,
        },
      },
    }),
  });

const deleteKybDocument = (id) =>
  callApi(`onboarding/kyb_documents/${id}`, {
    method: 'DELETE',
  });

const getUploadCredentials = (name, mimeType, path, type) =>
  callApi('upload_requests', {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'upload_request',
        attributes: {
          filename: name,
          mime_type: mimeType,
          path,
          type,
        },
      },
    }),
  });

const updateProfile = (values) => {
  const { id, firstName, lastName } = values;

  return callApi('settings/account', {
    method: 'PATCH',
    body: JSON.stringify({
      data: {
        type: 'account_settings',
        id,
        attributes: { first_name: firstName, last_name: lastName },
      },
    }),
  });
};

const setPassword = (values) => {
  const { merchantId, userId, newPassword } = values;

  return callApi('password', {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'password',
        id: 'set-password',
        attributes: {
          merchant_id: merchantId,
          user_id: userId,
          password: newPassword,
        },
      },
    }),
  });
};

const changePassword = (values) => {
  const { currentPassword, newPassword, authenticatorCode } = values;

  return callApi('settings/password', {
    method: 'PATCH',
    body: JSON.stringify({
      data: {
        type: 'password',
        id: 'change-password',
        attributes: {
          password: currentPassword,
          new_password: newPassword,
          tfa_token: authenticatorCode,
        },
      },
    }),
  });
};

const fetchRefund = (billId) => callApi(`payments/${billId}/refund`);

const refund = (values) => {
  const { billId, ...attributes } = values;

  return callApi(`payments/${billId}/refund`, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'payment_refund_proposal',
        attributes,
      },
    }),
  });
};

const resendConfirmationEmail = () =>
  callApi('email_confirmation', {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'merchant_email_confirmation',
        attributes: {},
      },
    }),
  });

const fetchUserEmail = () => callApi('email_confirmation_email');

const fetchTwoFactorAuthSecret = () =>
  callApi('multi_factor_auth/tfa_secret', {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'tfa_secret',
        attributes: { secret: 'true' },
      },
    }),
  });

const resetTwoFactorauth = (id, recoveryCode) =>
  callApi(`multi_factor_auth/tfa_secret/${id}`, {
    method: 'PATCH',
    body: JSON.stringify({
      data: {
        id,
        type: 'tfa_secret',
        attributes: { recovery_code: recoveryCode },
      },
    }),
  });

const enableTwoFactorAuth = (authenticatorCode) =>
  callApi('multi_factor_auth/tfa_activation', {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'tfa_activation',
        attributes: { code: authenticatorCode },
      },
    }),
  });

const disableTwoFactorAuth = (id, authenticatorCode) =>
  callApi(`multi_factor_auth/tfa_activation/${id}`, {
    method: 'DELETE',
    body: JSON.stringify({
      data: {
        type: 'tfa_activation',
        attributes: { code: authenticatorCode },
      },
    }),
  });

const generateNewStoreCredentials = (storeId) =>
  callApi('settings/store_credentials', {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'store_credentials',
        attributes: { store_uuid: storeId },
      },
    }),
  });

const onboardingSubmitCompanyType = ({ companyType, merchantId }) =>
  callApi('onboarding/company_type', {
    method: 'PATCH',
    body: JSON.stringify({
      data: {
        id: merchantId,
        type: 'company_type_submit',
        attributes: {
          company_type: companyType,
        },
      },
    }),
  });

const onboardingSubmitCompanyInfo = (values) => {
  const {
    id,
    storeName,
    storeUrl,
    companyOfficialName,
    registeredAddress,
    vatNumber,
    country,
    timezone,
    settlementCurrency,
    settlementBlockchain,
    referenceCurrency,
    merchantPurpose,
  } = values;
  return callApi('onboarding/company_info', {
    ...withFetchOptions(2),
    method: 'PATCH',
    body: JSON.stringify({
      data: {
        type: 'company_info',
        id,
        attributes: {
          store_name: storeName,
          store_url: storeUrl,
          company_official_name: companyOfficialName,
          registered_address: registeredAddress,
          purpose: merchantPurpose,
          country,
          timezone,
          vat_number: vatNumber,
          reference_fiat_currency: referenceCurrency,
          settlement_currency: settlementCurrency,
          settlement_blockchain: settlementBlockchain,
        },
      },
    }),
  });
};

const fetchCompanyInfo = (activeOrganizationId) =>
  callApi(`merchants/${activeOrganizationId}/company_info`);

const fetchSettlements = () => callApi('settlements');

const fetchSingleSettlement = (id) => callApi(`settlements/${id}`);

const fetchSingleWebhook = (id) => callApi(`webhooks/${id}`);

const fetchContract = () => callApi(`contract`);

const submitContract = (id, alwaysPayout, settlementCurrency) =>
  callApi('contract', {
    method: 'PATCH',
    body: JSON.stringify({
      data: {
        type: 'contract',
        id,
        attributes: {
          always_payout: alwaysPayout,
          next_settlement_currency: settlementCurrency,
        },
      },
    }),
  });

const submitPayoutAccount = (id, values) =>
  callApi('payout_accounts', {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'payout_account',
        id,
        attributes: {
          bank_account_number:
            values?.bankAccountNumber || values?.variableAccountTypeNumber,
          bank_country: values?.bankCountry,
          crypto_wallet_address: values?.cryptoWalletAddress,
          iban: values?.iban || values?.variableAccountTypeNumber,
          routing_number: values?.routingNumber,
          settlement_currency: values?.settlementCurrency,
          sort_code: values?.sortCode,
          swift_code: values?.swiftCode,
        },
      },
    }),
  });

const editPayoutAccount = (values) =>
  callApi(`payout_accounts/${values?.payoutAccount.id}`, {
    method: 'PATCH',
    body: JSON.stringify({
      data: {
        type: 'payout_account',
        id: values?.payoutAccount.id,
        attributes: {
          bank_account_number:
            values?.bankAccountNumber || values?.variableAccountTypeNumber,
          bank_country: values?.bankCountry,
          crypto_wallet_address: values?.cryptoWalletAddress,
          iban: values?.iban || values?.variableAccountTypeNumber,
          routing_number: values?.routingNumber,
          settlement_currency: values?.settlementCurrency,
          sort_code: values?.sortCode,
          swift_code: values?.swiftCode,
        },
      },
    }),
  });

const fetchPayoutAccounts = () => callApi(`payout_accounts`);

const refreshToken = (token) =>
  callUOApi('refresh-token', {
    method: 'POST',
    body: JSON.stringify({
      refreshToken: token,
    }),
  });

const exchangeOtpToken = (otpToken) =>
  callUOApi('otp/validate', {
    method: 'POST',
    body: JSON.stringify({
      otp: otpToken,
    }),
  });

const logout = (token) =>
  callUOApi('logout', {
    method: 'POST',
    body: JSON.stringify({
      refreshToken: token,
    }),
  });

const changePasswordUO = (values) => {
  const { currentPassword, newPassword } = values;

  return callUOApi('profile/change-password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken()}`,
    },
    body: JSON.stringify({
      currentPassword,
      newPassword,
    }),
  });
};

const fetchContractInfo = (merchantId) =>
  callApi(`merchants/${merchantId}/contract_info`);

export {
  apiRoot,
  cancelInvoice,
  changePassword,
  changePasswordUO,
  clearItem,
  createKybDocument,
  deleteKybDocument,
  disableTwoFactorAuth,
  editPayoutAccount,
  enableTwoFactorAuth,
  exportOrdersToCsv,
  exportSettlementReport,
  fetchUserEmail,
  fecthOrganizationStores,
  fetchAccountProfile,
  fetchOrderActivities,
  fetchBusinessDetails,
  fetchCompanyInfo,
  fetchContract,
  fetchAllCountries,
  fetchBankCountries,
  fetchBuyerCountries,
  fetchMerchantCountries,
  fetchFeatures,
  fetchInvoices,
  fetchInvoicePage,
  fetchKybDocuments,
  fetchOrderPage,
  fetchOrders,
  fetchOrganizations,
  fetchPayoutAccounts,
  fetchPermissions,
  fetchRefund,
  fetchSectors,
  fetchSettlements,
  fetchSingleSettlement,
  fetchStorePage,
  fetchTwoFactorAuthSecret,
  fetchWebhooks,
  fetchSingleWebhook,
  generateNewStoreCredentials,
  getItem,
  getAccessToken,
  getRefreshToken,
  getUploadCredentials,
  onboardingSubmitCompanyInfo,
  onboardingSubmitCompanyType,
  refund,
  resendConfirmationEmail,
  resetPassword,
  resetTwoFactorauth,
  sendForgottenPasswordEmail,
  setPassword,
  signIn,
  signOut,
  signUp,
  storeItem,
  submitBusinessDetails,
  submitContract,
  submitPayoutAccount,
  submitNewInvoice,
  twoFactorConfirmation,
  updateIntegrationType,
  updateProfile,
  exchangeOtpToken,
  refreshToken,
  logout,
  fetchContractInfo,
};
