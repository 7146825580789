import { Link } from '@react-pdf/renderer';
import React from 'react';

export const merchantAgreementData = ({
  merchantFeePercentage,
  minSettlementAmount,
  minSettlementAmountInWritten,
  merchantEmail,
  businessAddress,
  date,
}) => [
  {
    title: '1. Terms and Conditions regarding the Use of Platform',
    listItems: [
      {
        content:
          '1.1 The Merchant authorizes xMoney to start its Merchant onboarding process and undertakes to provide xMoney with any and all documents and information necessary for the compliance, by xMoney, of the legal and regulatory applicable provisions.',
      },
      {
        content:
          '1.2 xMoney accepts the Merchant as a Merchant on its Platform, and therefore, as soon as the onboarding process is concluded, and the Merchant’s Customers are able to pay for the Merchant’s products and/or services, and any third party is able to make transfers to the Merchant:',
        listItems: [
          {
            content: `1.2.1 xMoney will charge, for every transaction executed within the Platform, a ${merchantFeePercentage}% fee over the total amount of the value of the transaction, to be discounted from the amounts to be transferred to the Merchant under the terms of this agreement;`,
          },
          {
            content: `1.2.2 xMoney limits the settlement amount to a minimum of ${minSettlementAmount} (${minSettlementAmountInWritten}). If the Merchant wishes to receive an amount equal or lesser than ${minSettlementAmount}, additional fees may be applied.`,
          },
          {
            content: `1.2.3 The amount to be paid is calculated based on the exchange rate offered by xMoney’s exchange providers, which may include an additional spread that may depend on the type of cryptoasset, pairs, volatility, liquidity and amount of the transaction.`,
          },
          {
            content: `1.2.4 The Merchant will be entitled to access or qualify for any promotions or upgrades of services to be implemented in the future by xMoney.`,
          },
        ],
      },
      {
        content:
          '1.3 xMoney undertakes to actively assist the Merchant in the preparation of its application to become a Merchant on the Platform.',
      },
      {
        content:
          '1.4 In the event any technical and contractual changes must be implemented, the Merchant may accept, at its sole discretion, to implement those changes with xMoney’s assistance, provided such changes do not alter, in substance, the Agreement and its underlying purposes, as provided in the recitals above.',
      },
      {
        content: `1.5 For the purposes of the Agreement, “Platform” means xMoney technology and service platform, as well as all associated software (which, for the avoidance of doubt, shall not include any website, software, license owned, held or otherwise granted to the Merchant or any of its Affiliates), and the executable, object code version of such software and platform, including the features and services that are made available or intermediated by xMoney, and any in other xMoney-branded websites (including domains, international versions, widgets and mobile versions), together with any associated application program interface (“API”), documentation source code, object code library, executable applications and other materials, any plugins, and any other media, brands, products, services, devices or networks now existing or later developed by xMoney or its Affiliates, together with any updates.`,
      },
      {
        content: `1.6 For the purposes of the Agreement, “Affiliate” means any other Entity that directly, or indirectly through one or more intermediaries, controls or is controlled by, or is under common control, with any of the Parties.`,
      },
      {
        content: `1.7 The Merchant agrees that xMoney shall have the right, at any time, due to legal and regulatory requirements, to refuse to process any Transactions for any Merchant or any of its Merchant’s Customers within the Platform, if xMoney reasonably believes or suspects that has been a violation of the applicable law or contract or in the event there is a material increase in anticipated risk presented by such Merchant’s Customer or transaction.`,
      },
      {
        content: `1.8 xMoney may, at its sole discretion, if so requested by the Merchant, implement any additional controls or policies regarding any transactions, provided such implementation is possible from a technical standpoint and is compliant with applicable laws and regulations.`,
      },
    ],
  },
  {
    title: '2. Services',
    listItems: [
      {
        content:
          "2.1 xMoney undertakes to provide the Merchant with the Services that will enable the Merchant's Customers to make payments using virtual currencies from their own wallets or accounts, and also any entity and/or person to transfer a certain amount in virtual currency, through the Platform, to the Merchant, which shall receive the corresponding amount in its preferred currency.",
      },
      {
        content: [
          '2.2 For the purposes of the Agreement, the ',
          <strong key="services">&ldquo;Services&rdquo;</strong>,
          " comprise the set of services and products provided by xMoney with the purpose of (i) allowing the Merchant's Customers to pay for the Merchant's products and/or services in virtual currencies, on behalf of the latest, and (ii) allowing any third entity and/or person to transfer a certain amount in virtual currency, to the Merchant, which shall then receive the corresponding amount in its preferred currency pursuant to which xMoney, together with any third party providers to be defined by xMoney, and resourcing to them, establishes the adequate virtual currency transaction processing mechanisms by means of the use of the Platform, mechanisms that will include the facilitation of processing, storage, receipt, transmission, disbursement, and access to virtual currencies as payment instruments, and facilitating the conversion of virtual currencies into fiat currencies through third-parties.",
        ],
      },
      {
        content: [
          '2.3 For the purposes of this agreement, ',
          <strong key="transaction">&ldquo;Transaction&rdquo;</strong>,
          " means any transaction conveyed through the Platform whereby (i) a buyer uses virtual currency as a means of payment, via the Platform, of service and/or product commercialized by the Merchant after the Merchant's onboarding process is concluded, and/ or (ii) any third entity and/or person transfers a certain amount in virtual currency, to the Merchant, which shall then receive the corresponding amount in its preferred currency. On the other hand, ",
          <strong key="refund">&ldquo;Refund&ldquo;</strong>,
          ' means a reversal of a Transaction.',
        ],
      },
      {
        content:
          '2.4 The Parties shall take all actions necessary throughout the term of the present agreement to ensure compliance with any applicable laws, statutes, ordinances, governmental rules and regulations, as they may be enacted or amended from time to time relating to services and transactions undertaken through the Platform.',
      },
      {
        content:
          '2.5 Both Parties expressly, irrevocably and unequivocally agree and declare that under no circumstances xMoney assumes any obligation towards, or related with, the Merchant’s Customers, with whom there is no contractual, or other relationship of any kind. More specifically, but without limitation, both Parties expressly, irrevocably and unequivocally declare and accept that xMoney has not the obligation, nor the right, to execute any onboarding process regarding the Merchant’s Customers, since such onboarding process is of sole and unique responsibility of the Merchant. While xMoney does perform additional screenings at certain thresholds, such due diligence is carried out for its own internal risk management, not to ensure any Merchant’s compliance with any rules and regulations merchants may be obliged to. xMoney may issue refunds to the Merchant’s Customers through the Platform. However, in relation to payments in virtual assets made to the Second Party, any legal dispute relations are solely between the Merchant and its customers. xMoney is not a party to relations between the Second Party and its customers making payments in virtual assets. xMoney does not accept any queries, letters, requests and/or complaints as well as liability for the quality, legality, safety, delivery or any other aspect regarding the Merchant, the Merchant’s services, products, activities and/or payment and/or a donation, a purchase related to the relation between the Second Party and its customers. Any disputes between the Second Party and its customers shall be resolved exclusively between themselves.',
      },
      {
        content:
          '2.6 Without prejudice of our own, xMoney shall not control, monitor, assess, authorise and/or supervise the activities of the Second Party and its own customers purchases. The foregoing does not preclude xMoney from exercising its right to investigate, suspend, restrict or terminate the provision of Services to the Second Party at any time, as well as to refuse to complete, block or reverse a transaction initiated by the Merchant’s customer if it reasonably believes that it violates these terms or there are other circumstances that may infringe xMoney, the Second Party’s or the public’s legitimate interests.',
      },
    ],
  },
  {
    title: '3. Payments and flow of funds ',
    listItems: [
      {
        content:
          '3.1 xMoney will provide the Services to the Merchant according to the following flow:',
        listItems: [
          {
            content:
              '3.1.1 xMoney will enable the use of its Platform as an alternative payment method for the Merchant’s Customers through a specifically design interface to be presented to the Merchant’s Customers regarding a Transaction, after exiting the Merchant’s online store Checkout website or equivalent, or as an alternative mean to ensure that the Merchant requests any third entity and/or person to transfer any amounts in virtual currency, that then shall be received in the Merchant’s preferred currency.',
          },
          {
            content:
              '3.1.2 The Platform will be presented, and each Merchant’s Customer (in relation to a specific Transaction) and entity/person will be prompted with the option to transfer an amount of virtual currencies equivalent in market value to the price of the goods or services, as per determined by the Platform, or to a certain amount in virtual currency that is transferred to an address which xMoney, via a third party provider, undertakes to provide the Merchant’s Customer and/or the entity/person with.',
          },
          {
            content:
              '3.1.3 xMoney will then analyse and, within its limited possibilities, validate the virtual currency used by the Merchant’s Customers and/or by the entity/person, according to its internal technical processes. Whenever the Merchant chooses to receive the funds in virtual currency, xMoney transfers the due amount directly to its corresponding address. When the preference is for fiat currency, the following steps will take place.',
            listItems: [
              {
                content:
                  '3.1.3.1 xMoney then shall ensure that a third-party provider, which will be responsible for the conversion of the virtual currencies transferred by the Merchant’s Customer or by the entity/person into fiat currency, after receiving the amount, starts the conversion process.',
              },
              {
                content:
                  '3.1.3.2 Further to the conversion to be executed with  the said third-party provider, the corresponding fiat currency amount will be held in an account to be opened by a third-party provider on behalf of the Merchant, that hereby expressly accepts that circumstance, waiting for the instructions of the Merchant to transfer such amount to a specific Merchant’s bank account, deducted from the applicable fee of the Transaction’s value.',
              },
              {
                content:
                  '3.1.3.3 After the fiat currency is transferred to the account indicated by the Merchant, the Settlement will be terminated.',
              },
            ],
          },
        ],
      },
      {
        content:
          '3.2 The use of the Platform will generate its own Transaction Receipt, to be delivered to the Merchant, to the Merchant’s Customer and to the third-party entity and/or person, that will include, at least, the following information:',
        listItems: [
          {
            content: '3.2.1 the transaction date;',
          },
          {
            content:
              '3.2.2 a brief description of the goods or services sold, returned or cancelled;',
          },
          {
            content:
              '3.2.3 the price of the goods or services, including the applicable taxes (as provided by the Merchant), or amount of any credit or adjustment;',
          },
          {
            content:
              '3.2.4 the Merchant’s name in a manner recognizable to the Merchant’s Customers;',
          },
          {
            content:
              '3.2.5 any other information required by the Agreement or by law or regulation (as provided by the Merchant).',
          },
        ],
      },
      {
        content:
          '3.3 When processing Transactions on behalf of the Merchant, xMoney will not implement any of its own refund policies (without prejudice to those referring to non-complete transactions) and xMoney’s intervention will be limited to the use of the Platform to enable payments to be made in virtual currencies to the Merchant, and to ensure, via a third party provider, the reception and the conversion of the virtual currencies into fiat, and the delivery of the converted fiat amount or the virtual currency to the Merchant.',
      },
      {
        content:
          '3.4 Any refund which relates to Complete Transactions, i.e., Transactions that have already been validated and marked by xMoney as complete and processed, shall be dealt with by the Merchant, who will be responsible, at its own and sole discretion, and according to its internal refund policies, to solve the situation.',
      },
      {
        content:
          '3.5 xMoney will be responsible for any Refund derived from non-completed Transactions, i.e., payments and/or transactions that have not been validated by the xMoney Platform for technical or regulatory reasons, and which have not been qualified and marked by xMoney as complete and processed.',
      },
      {
        content:
          '3.6 Any refunded amount in virtual currency will be indexed to the market exchange rate, determined according to the price (plus consumption taxes) of goods and services as quoted by the Merchant in fiat currency. Network fees are non-refundable.',
      },
      {
        content:
          '3.7 xMoney is exempted from the obligation to refund transactions resulting from the usage of a wrong token to pay for the said transaction, as well as from refunding directly on the lightning networks and from refunding the transaction fees for the said transaction.',
      },
    ],
  },
  {
    title: '4. xMoney liabilities and responsibilities',
    listItems: [
      {
        content: `4.1 xMoney will charge the Merchant a ${merchantFeePercentage}% fee over the total amount of the value of the transaction, to be discounted from the amounts to be transferred to the Merchant under the terms of this agreement.`,
      },
      {
        content:
          '4.2 xMoney, via the third-party providers which will execute such virtual currency to fiat currency conversion services, accepts the currency exchange conversion risk and will, in any case, ensure the Merchant receives the corresponding amount in EURO (or another currency chosen by the Merchant during its onboarding process) as labelled and stated at the Merchant’s store Checkout website, which corresponds to the price of the product and/or service in addition to any taxes to be collected from the Merchant’s Customer, as instructed by the Merchant.',
      },
      {
        content:
          '4.3 The risks associated with fluctuations in the Exchange Rate lie with xMoney, with the Merchant’s Customers or the third entity and/or person which has transferred virtual currency to the Merchant, but not with the Merchant.',
      },
      {
        content:
          '4.4 xMoney will ensure that the Merchant receives the full amount paid by the Merchant’s Customer or by the third entity and/or person, in fiat currency (without prejudice to deducting the applicable fee, as the provisions included in this agreement), for any goods or services sold by the latter to the Merchant’s Customers, or for any requested transfer, using the Platform.',
      },
      {
        content:
          '4.5 A Transaction shall only be completed when xMoney confirms that the Merchant’s Customer has transferred the amount of virtual currencies required to be converted into the fiat currency amount quoted by the Merchant for a particular service or good, and after xMoney has conducted its internal validation processes.',
      },
      {
        content:
          '4.6 In the event any of the Parties fails to detect fraudulent Transactions, xMoney shall not be held responsible for any losses and/or damages suffered by the Merchant deriving from fraudulent or unauthorized Transactions.',
      },
      {
        content:
          '4.7 The Merchant is the sole and unique responsible for the assessment, liquidation and payment of any taxes (including any interest, additions to tax or penalties) related to the sale of goods and services commercialized via the Platform.',
      },
      {
        content:
          '4.8 Notwithstanding the exclusions and limitations of liability referred to in the previous subparagraphs, the Parties expressly agree that in case xMoney is to be held liable towards the Merchant for any purpose whatsoever, any xMoney’s liability will always be limited to the amounts of the Transaction in cause.',
      },
      {
        content:
          '4.9 xMoney is the sole responsible, towards the Merchant, for the assessment, liquidation and payment of any taxes (including any interest, additions to tax or penalties) related to the collection, payment and conversion of virtual currencies.',
      },
      {
        content:
          '4.10 Except as and to the extent expressly provided otherwise in the Agreement, neither Party shall be liable to the other Party for any indirect and/or consequential damages, such as in particular, but without limitation, loss of opportunity, loss of business, loss of profits and/or frustrated costs/expenses.',
      },
    ],
  },
  {
    title: '5. Merchant obligations',
    listItems: [
      {
        content:
          '5.1 The Merchant agrees to provide all information and documentation requested by xMoney following its obligations to comply with applicable laws of Anti-Money Laundering and Counter Financing of Terrorism. The Merchant also agrees to maintain its information and documentation accurate and up-to-date.',
      },
      {
        content:
          '5.2 The Merchant shall comply with the terms of this Agreement and all applicable laws and regulations, as well as any procedures, regulations, industry best practices, applicable laws of Anti-Money Laundering and Counter Financing of Terrorism, data protection, consumer protection and any other applicable legislation for the use of the Platform and the performance of its obligations.',
      },
      {
        content:
          '5.3 The Merchant shall notify xMoney of any incident that would prevent it from complying with its obligations.',
      },
      {
        content:
          '5.4 The Merchant shall promptly notify xMoney of any inquiry received, including those from any media or governmental authority.',
      },
      {
        content:
          '5.5 The Merchant shall ensure that its personnel comply with the terms of this Agreement and to all applicable laws and regulations.',
      },
      {
        content:
          '5.6 This Agreement does not constitute an employment relationship nor any joint venture, trust, fiduciary, agency agreement or other relationship between the parties, other than the contractual relationship expressly provided for in it. None of the parties shall have, nor shall represent that they have, any authority to make any commitments on the other party’s behalf.',
      },
      {
        content:
          '5.7 The Merchant is solely responsible to pay all applicable taxes arising from the payment of the fees under this Agreement.',
      },
      {
        content:
          '5.8 The Merchant may not use xMoney’s logos or trademarks except to perform its obligations under this Agreement. xMoney’s trademarks or logos are property of xMoney. The Merchant limited right to use the trademarks of xMoney does not grant the Merchant any right or ownership in respect to those. ',
      },
      {
        content: [
          '5.9 The Merchant will not use the Platform to serve buyers from any non-supported jurisdiction as described ',
          <Link
            key="link"
            href="https://support.xmoney.com/en/articles/4879553-merchants-is-xmoney-available-for-all-of-my-clients"
          >
            here
          </Link>,
          '.',
        ],
      },
      {
        content: [
          '5.10 The Merchant may not engage in any of the non-supported sectors as described ',
          <Link
            key="link"
            href="https://support.xmoney.com/en/articles/4388053-is-my-business-sector-restricted"
          >
            here
          </Link>,
          '.',
        ],
      },
      {
        content: '5.11 The Merchant will not conduct its business in a manner:',
        listItems: [
          {
            content: '• which is unlawful or fraudulent;',
          },
          {
            content: '• which is likely to be ofensive or upseting;',
          },
          {
            content:
              '• that promotes any content which is deceptive, abusive, violent, illegal, harmful, unethical or it might infringe any of xMoney or third party rights.',
          },
        ],
      },
    ],
  },
  {
    title: '6. Exchange of information',
    listItems: [
      {
        content:
          'Without prejudice of the applicable laws and regulations, as well as internal policies, concerning the sharing of internal and confidential information, the Parties shall prepare and provide any additional information or materials that may be reasonably requested by the other Party to enable it to comply with its internal policies regarding accounting or disclosure requirements as well as the requirements of any applicable regulatory or governmental authority, namely for the purposes of complying with regulatory impositions.',
      },
    ],
  },
  {
    title: '7. Confidentiality',
    listItems: [
      {
        content:
          '7.1 For purposes of this Agreement, the term “Confidential Information” includes but is not limited to the following: any information, business plan, concept, idea, know-how, process, technique, program, design, formula, algorithm or work-in-progress, any engineering, manufacturing, marketing, technical, financial, data or sales information, pricing or business, or any information regarding suppliers, customers, employees, investors, business operations, and any other information or materials, whether written or graphic, or any other form that is disclosed orally, electronically, or any other form or way, which is learned or disclosed in the course of discussions, studies, or other work undertaken between the parties. Without limiting the generality of the foregoing, Confidential Information shall include all information and materials disclosed orally or in any other form, regarding xMoney’s products or product development including, but not limited to, the configuration techniques, data classification techniques, data modeling and management techniques, data structures, and other information of or relating to xMoney’s products.',
      },
      {
        content:
          '7.2 The Merchant agrees not to disclose, at any time, give or transmit in any matter or form or for any purpose, the Confidential Information received from xMoney or any of its partners to any person, party, firm or corporation entity, or use such Confidential Information for its own benefit or the benefit of anyone else, or for any purpose other than referred in this agreement. Without limitation of the generality of the foregoing, the Merchant may not use, refer to, or otherwise benefit from the Confidential Information from the Company or any of its partners. The Merchant shall not disclose the xMoney’s Confidential Information to third parties without the xMoney’s written consent.',
      },
      {
        content:
          '7.3 The Parties agree that the existence and contents of the Agreement shall be treated as Confidential Information.',
      },
      {
        content:
          '7.4 The Merchant shall take all reasonable measures to preserve the confidentiality and avoid the disclosure of the Company’s Confidential Information.',
      },
      {
        content:
          '7.5 The Merchant agrees to return all Confidential Information received from xMoney or any of its partners, upon the termination of this Agreement, or upon the request of xMoney, without retaining any copies or extracts thereof.',
        listItems: [
          {
            content:
              '7.5.1 is or becomes available to the public knowledge other than by breach of the Agreement; or',
          },
          {
            content:
              "7.5.2 is rightfully in the recipient Party's possession via a third party entitled to disclose the Information; or",
          },
          {
            content:
              '7.5.3 is disclosed with the written consent of the disclosing Party.',
          },
        ],
      },
      {
        content:
          '7.6 The Merchant shall, for a period of 5 (five) years from the date of disclosure of the Information, maintain the information confidential and, in particular, shall not without the written consent of xMoney disclose the information to any third party.',
      },
      {
        content:
          '7.7 The above confidentiality obligations shall not apply if the Party is compelled to disclose Confidential Information pursuant to any law, legal process, regulation or regulatory process.',
      },
      {
        content:
          '7.8 For the purposes of the previous paragraph, the Disclosing Party must inform, if not legally barred, of the existence of the disclosure obligation.',
      },
      {
        content:
          '7.9 The Parties will provide reasonable assistance to and cooperate with each other in order to preserve the confidential nature of the Confidential Information.',
      },
    ],
  },
  {
    title: '8. Non assignments or third party beneficiaries',
    listItems: [
      {
        content:
          '8.1 The Agreement is solely for the benefit of the Parties and their respective successors and permitted assigns, and the Agreement shall not otherwise be deemed to confer upon or give to any other third party, any remedy, claim, liability, reimbursement, cause of action, or other right.',
      },
      {
        content:
          '8.2 No Party shall assign its rights under the Agreement without the prior written consent of the other Parties or as provided in the Agreement. Any purported assignment without such consent shall be null and void, and non-effective towards the other Party.',
      },
    ],
  },
  {
    title: '9. Entire Agreement and Amendment',
    listItems: [
      {
        content:
          '9.1 This Agreement constitutes the entire agreement between the parties with respect to the subject matter addressed herein. This Agreement may not be amended or modified except in writing signed by both parties.',
      },
      {
        content:
          '9.2 To be effective, notices sent pursuant to this Agreement must be in writing and delivered by electronic mail to the electronic mail address set out in Section 10. An exception is made to notices given in legal proceedings or arbitration, which shall be addressed to the physical address set out in the same section.',
      },
      {
        content:
          '9.3 The Agreement supersedes all prior understandings, agreements and undertakings, oral or written, among the Parties with respect to the subject matter of the Agreement.',
      },
      {
        content:
          '9.4 The Agreement does not supersede or dispense the Merchant from the explicit acceptance of the Terms of Service of the platform disclosed at the website (https://www.xmoney.com/legal-acknowledgement), which must be agreed in full for the continuous use of the platform. In the event of any conflict between the above-mentioned Terms of Service and this Agreement, this Agreement shall prevail.',
      },
      {
        content:
          '9.5 The undertakings of this Agreement, particularly the Sections of Privacy, Confidentiality and Intelectual Property, shall continue after the termination of the Agreement or any discussions between the parties.',
      },
    ],
  },
  {
    title: '10. Information and contact details',
    listItems: [
      {
        content:
          '10.1 Communications between the parties are to be in English. This Agreement is concluded in English and all communications between the parties shall be in English only.',
      },
      {
        content: `10.2 The Company may be contacted in writing by email to compliance@xmoney.com or to the following physical address: ${businessAddress}`,
      },
      {
        content:
          '10.3 The Merchant can be contacted throught the following channels:',
        listItems: [
          {
            content: `Email address: ${merchantEmail}`,
          },
        ],
      },
    ],
  },
  {
    title: '11. Term and termination',
    listItems: [
      {
        content: `11.1 The Agreement becomes effective on ${date} and shall remain in full force and effect for an initial period of 1 (one) year. The Agreement may be renewed for additional periods of 6 (six) months, except if any of the Parties notifies the other Party in writing to the contrary at least 30 (thirty) days prior to the date of such renewal.`,
      },
      {
        content:
          '11.2 Either party shall have the right to terminate the Agreement at any time upon 30 (thirty) days written notice to the other party, provided however, that such termination shall not impair or affect any accrued rights or due payments.',
      },
      {
        content:
          '11.3 The Merchant may terminate the Agreement by means of written notice sent by any means, based on xMoney’s breach of any obligation under the terms of this agreement, notably if xMoney fails to pay any amounts according to the Agreement provisions payable to the Merchant within the applicable deadlines or fails to settle the debt within 10 (ten) days as from the date of notice.',
      },
      {
        content:
          '11.4 xMoney may terminate the Agreement by means of a written notice, if the Merchant has failed to perform or comply with its obligations set out in the Agreement.',
      },
    ],
  },
  {
    title: '12. Disputes and Applicable Law',
    listItems: [
      {
        content:
          '12.1 This Agreement shall be governed by and construed in accordance with the laws of Estonia.',
      },
      {
        content:
          '12.2 Any dispute, controversy or claim arising out of or in connection with this Agreement (each a “Dispute”) shall be primarly resolved by mutual negotiation between the Parties. In the event mutual negotiation fails to resolve a Dispute between the Parties within 30 (thirty) days of the Parties first meeting to mutually negotiate a resolution to that Dispute, either party may commence legal proceedings.',
      },
      {
        content:
          '12.3 The seat of arbitration shall be the Courts of Braga - Portugal.',
      },
      {
        content:
          '12.4 If one or more provisions of this contract are held to be unenforceable under applicable law, the Parties agree to renegotiate such provision in good faith.',
      },
    ],
  },
];
