import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { getTitlePrefix as getTitlePrefixBase } from 'settlements/utils/getTitlePrefix';
import { amount as amountPropType } from 'common/propTypes';
import { commonStyles } from './pdfCommonStyles';
import { feesProp } from '../../propTypes';
import parseNegativeValues from '../../utils/parseNegativeValues';
import relativeToPercentage from '../../utils/relativeToPercentage';
import { columnWidths } from './ReportTable';

const styles = StyleSheet.create({
  container: {
    marginTop: 16,
  },
  title: {
    fontSize: 14,
    lineHeight: 1.14,
    marginBottom: 4,
    marginLeft: 8,
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 4,
  },
  rowItem: {
    paddingVertical: 4,
    paddingHorizontal: 8,
    fontSize: 10,
    lineHeight: 1.2,
    color: '#252D3D',
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomColor: '#E4E4E7',
    marginVertical: 4,
  },
});

const firstFourColumnsTotalWidth = Object.values(columnWidths)
  .slice(0, 4)
  // remove %
  .reduce((acc, curr) => acc + Number(curr.slice(0, -1)), 0);

const lastTwoColumnWidths = Object.values(columnWidths).slice(-2);

const Row = ({ title, values, isTotal }) => (
  <View style={styles.rowContainer}>
    <Text
      style={[
        styles.rowItem,
        { width: `${firstFourColumnsTotalWidth}%` },
        isTotal && commonStyles.semibold,
      ]}
    >
      {title}
    </Text>
    {values.map(({ amount, currency }, index) => (
      <Text
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        style={[
          styles.rowItem,
          {
            width: lastTwoColumnWidths[index],
            textAlign: 'right',
          },
          isTotal && commonStyles.semibold,
        ]}
      >
        {`${amount} ${currency}`}
      </Text>
    ))}
  </View>
);

Row.propTypes = {
  title: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
    })
  ).isRequired,
  isTotal: PropTypes.bool,
};

const ReportsTotals = ({
  totalAmount,
  toBeSettledAmount,
  toBeSettledReferenceAmount,
  totalInvoicesAmount,
  totalFiatBillsAmount,
  totalOrdersAmount,
  totalReferenceAmount,
  totalReferenceInvoicesAmount,
  totalReferenceOrdersAmount,
  totalReferenceRefundedAmount,
  totalReferenceFiatBillsAmount,
  totalRefundedAmount,
  fees,
  referenceFees,
  referenceCurrency,
  shouldShowReferenceCurrency,
  isInvoicingEnabled,
  isOnrampEnabled,
}) => {
  const {
    utrustFeeMultiplier,
    utrustFeeTotal,
    utrustFeeVat,
    utrustFeeVatAmount,
    utrustPayoutExtraFee,
  } = fees;

  const payoutFeeNumber = Number(utrustPayoutExtraFee);
  const settlementCurrency = toBeSettledAmount.currency;

  const getTitlePrefix = (section) =>
    getTitlePrefixBase(section, { isInvoicingEnabled, isOnrampEnabled });

  return (
    <View style={styles.container} wrap={false}>
      <Text style={[styles.title, commonStyles.semibold]}>Breakdown</Text>
      <Row
        title={`${getTitlePrefix('order')}. Total Orders Paid`}
        values={[
          ...(shouldShowReferenceCurrency
            ? [
                {
                  amount: totalReferenceOrdersAmount,
                  currency: referenceCurrency,
                },
              ]
            : []),
          {
            amount: totalOrdersAmount.value,
            currency: totalOrdersAmount.currency,
          },
        ]}
      />

      {isInvoicingEnabled && (
        <Row
          title={`${getTitlePrefix('invoice')}. Total Invoices Paid`}
          values={[
            ...(shouldShowReferenceCurrency
              ? [
                  {
                    amount: totalReferenceInvoicesAmount,
                    currency: referenceCurrency,
                  },
                ]
              : []),
            {
              amount: totalInvoicesAmount.value,
              currency: totalInvoicesAmount.currency,
            },
          ]}
        />
      )}

      {isOnrampEnabled && (
        <Row
          title={`${getTitlePrefix('fiatBill')}. Total Onramp`}
          values={[
            ...(shouldShowReferenceCurrency
              ? [
                  {
                    amount: totalReferenceFiatBillsAmount,
                    currency: referenceCurrency,
                  },
                ]
              : []),
            {
              amount: totalFiatBillsAmount.value,
              currency: totalFiatBillsAmount.currency,
            },
          ]}
        />
      )}

      <View style={styles.divider} />

      <Row
        title="Subtotal payments"
        values={[
          ...(shouldShowReferenceCurrency
            ? [{ amount: totalReferenceAmount, currency: referenceCurrency }]
            : []),
          { amount: totalAmount.value, currency: settlementCurrency },
        ]}
        isTotal
      />

      <Row
        title={`xMoney service fee - ${relativeToPercentage(
          utrustFeeMultiplier
        )}%`}
        values={[
          ...(shouldShowReferenceCurrency
            ? [
                {
                  amount: parseNegativeValues(referenceFees?.utrustFeeTotal),
                  currency: referenceCurrency,
                },
              ]
            : []),
          {
            amount: parseNegativeValues(utrustFeeTotal),
            currency: settlementCurrency,
          },
        ]}
      />

      {payoutFeeNumber > 0 && (
        <Row
          title="xMoney payout fee"
          values={[
            ...(shouldShowReferenceCurrency
              ? [
                  {
                    amount: parseNegativeValues(
                      referenceFees?.utrustPayoutExtraFee
                    ),
                    currency: referenceCurrency,
                  },
                ]
              : []),
            {
              amount: parseNegativeValues(utrustPayoutExtraFee),
              currency: settlementCurrency,
            },
          ]}
        />
      )}

      <Row
        title={`VAT fee - ${relativeToPercentage(utrustFeeVat)}%`}
        values={[
          ...(shouldShowReferenceCurrency
            ? [
                {
                  amount: parseNegativeValues(
                    referenceFees?.utrustFeeVatAmount
                  ),
                  currency: referenceCurrency,
                },
              ]
            : []),
          {
            amount: parseNegativeValues(utrustFeeVatAmount),
            currency: settlementCurrency,
          },
        ]}
      />

      <Row
        title={`${getTitlePrefix('refund')}. Total Orders Refunded`}
        values={[
          ...(shouldShowReferenceCurrency
            ? [
                {
                  amount: parseNegativeValues(totalReferenceRefundedAmount),
                  currency: referenceCurrency,
                },
              ]
            : []),
          {
            amount: parseNegativeValues(totalRefundedAmount.value),
            currency: settlementCurrency,
          },
        ]}
      />

      <View style={styles.divider} />

      <Row
        title="Total payout"
        values={[
          ...(shouldShowReferenceCurrency
            ? [
                {
                  amount: toBeSettledReferenceAmount,
                  currency: referenceCurrency,
                },
              ]
            : []),
          { amount: toBeSettledAmount.value, currency: settlementCurrency },
        ]}
        isTotal
      />
    </View>
  );
};

ReportsTotals.propTypes = {
  fees: feesProp,
  toBeSettledAmount: amountPropType.isRequired,
  totalAmount: amountPropType,
  totalInvoicesAmount: amountPropType,
  totalFiatBillsAmount: amountPropType,
  totalOrdersAmount: amountPropType,
  totalRefundedAmount: amountPropType,
  totalReferenceAmount: PropTypes.string,
  totalReferenceInvoicesAmount: PropTypes.string,
  totalReferenceOrdersAmount: PropTypes.string,
  totalReferenceRefundedAmount: PropTypes.string,
  totalReferenceFiatBillsAmount: PropTypes.string,
  referenceCurrency: PropTypes.string,
  shouldShowReferenceCurrency: PropTypes.bool,
  isInvoicingEnabled: PropTypes.bool.isRequired,
  isOnrampEnabled: PropTypes.bool.isRequired,
};

export default ReportsTotals;
