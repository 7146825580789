import Arrow from './components/Arrow';
import Caret from './components/Caret';
import Circle from './components/Circle';
import Clock from './components/Clock';
import Cog from './components/Cog';
import CheckCircle from './components/CheckCircle';
import Cross from './components/Cross';
import CrossThin from './components/CrossThin';
import Download from './components/Download';
import Eye from './components/Eye';
import EyeCrossed from './components/EyeCrossed';
import Info from './components/Info';
import Invoice from './components/Invoice';
import Lock from './components/Lock';
import SymbolxMoney from './components/SymbolxMoney';
import Mail from './components/Mail';
import NavMenu from './components/NavMenu';
import Orders from './components/Orders';
import Organization from './components/Organization';
import Play from './components/Play';
import Plug from './components/Plug';
import PlugCrossed from './components/PlugCrossed';
import Plus from './components/Plus';
import QuestionCircle from './components/QuestionCircle';
import Settlements from './components/Settlements';
import Share from './components/Share';
import Store from './components/Store';
import CodeRec from './components/CodeRec';
import Gear from './components/Gear';
import Invoice2 from './components/Invoice2';
import ShoppingCart from './components/ShoppingCart';
import ShieldCheck from './components/ShieldCheck';

import { ReactComponent as Checkmark } from './files/checkmark.svg';
import { ReactComponent as Copy } from './files/copy.svg';
import { ReactComponent as Document } from './files/document.svg';
import { ReactComponent as Dots } from './files/dots.svg';
import { ReactComponent as ErrorSign } from './files/error.svg';
import { ReactComponent as Logout } from './files/logout.svg';
import { ReactComponent as NoOrders } from './files/no_orders.svg';
import { ReactComponent as NoSettlements } from './files/no_settlements.svg';
import { ReactComponent as PlusBubble } from './files/plus_bubble.svg';
import { ReactComponent as Power } from './files/power.svg';
import { ReactComponent as Search } from './files/search.svg';
import { ReactComponent as SuspendedSettlements } from './files/suspended_settlements.svg';
import { ReactComponent as NoPersonal } from './files/no_personal.svg';
import { ReactComponent as Retry } from './files/retry.svg';
import { ReactComponent as BannerLogo } from './files/banner_logo.svg';
import { ReactComponent as Comment2Question } from './files/comment_2_question.svg';
import { ReactComponent as Bell } from './files/bell.svg';
import { ReactComponent as Question } from './files/question.svg';

export default {
  Arrow,
  BannerLogo,
  Bell,
  Caret,
  Circle,
  CheckCircle,
  Checkmark,
  Clock,
  CodeRec,
  Cog,
  Comment2Question,
  Copy,
  Cross,
  CrossThin,
  Document,
  Dots,
  Download,
  ErrorSign,
  Eye,
  EyeCrossed,
  Gear,
  Info,
  Invoice,
  Invoice2,
  Lock,
  SymbolxMoney,
  Logout,
  Mail,
  NavMenu,
  NoOrders,
  NoSettlements,
  NoPersonal,
  Orders,
  Organization,
  Play,
  Plug,
  PlugCrossed,
  Plus,
  PlusBubble,
  Power,
  QuestionCircle,
  Question,
  Retry,
  Search,
  Settlements,
  Share,
  ShieldCheck,
  SuspendedSettlements,
  Store,
  ShoppingCart,
};
