/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import signature from './signature.jpeg';
import { numberToWords } from './numberToWords';
import { merchantAgreementData } from './merchantAgreementData';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Times-Roman',
    padding: 40,
    fontSize: 12,
    lineHeight: 1.6,
    color: '#333',
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: 24,
    color: '#1a1a1a',
  },
  paragraph: {
    marginBottom: 12,
    textAlign: 'justify',
  },
  strong: {
    fontFamily: 'Times-Bold',
  },
  italic: {
    fontFamily: 'Times-Italic',
  },
  listTitle: {
    marginTop: 12,
    marginBottom: 8,
  },
  listItem: {
    marginLeft: 12,
    marginBottom: 8,
    textAlign: 'justify',
  },
  link: {
    color: '#0066cc',
    textDecoration: 'underline',
  },
  signatureBlock: {
    marginTop: 24,
  },
  signatureRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 12,
  },
  signatureColumn: {
    width: '45%',
  },
  signatureLine: {
    borderTop: 1,
    borderColor: '#000',
    width: '100%',
    marginTop: 48,
    marginBottom: 8,
  },
  signatureImage: {
    width: 150,
    height: 50,
    marginBottom: 8,
  },
});

const RecursiveListItems = ({ items, level = 0 }) => {
  if (!items) return null;

  return items.map((item) => (
    <>
      {item.content && (
        <Text style={{ marginLeft: 12 * level }}>
          {Array.isArray(item.content)
            ? item.content.map((contentItem, contentIndex) => (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={contentIndex}>
                  {contentItem}
                </React.Fragment>
              ))
            : item.content}
        </Text>
      )}
      {item.listItems && (
        <RecursiveListItems items={item.listItems} level={level + 1} />
      )}
    </>
  ));
};

const MerchantServiceAgreementPDF = ({ contractInfo }) => {
  const {
    companyOfficialName,
    country,
    date,
    companyNumber,
    businessAddress,
    email,
    merchantFeePercentage,
    minSettlementAmount,
    representativeName,
  } = contractInfo;

  const minSettlementAmountInWritten = numberToWords(
    Number(minSettlementAmount)
  );
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.title}>MERCHANT SERVICE AGREEMENT</Text>

        <Text style={[styles.paragraph, styles.italic]}>
          This agreement (the "Agreement") is made as of {date} (the "Effective
          Date") between the parties:
        </Text>

        <Text style={styles.paragraph}>
          <Text style={styles.strong}>Modernity Party OU</Text>, a company
          incorporated and organized under the laws of Estonia, with company
          number 14831182, with registered offices at Tornimäe tn 3/5/7,
          Kesklinna linnaosa, Tallinn, Harju maakond, 10145 henceforth First
          Party or "xMoney"; and
        </Text>

        <Text style={styles.paragraph}>
          <Text style={styles.strong}>{companyOfficialName}</Text>, a company
          incorporated and organized under the laws of {country},{' '}
          {companyNumber !== null
            ? `with company number ${companyNumber}, `
            : ''}
          with registered offices at {businessAddress}, henceforth Second Party
          or "Merchant", individually a "Party" and collectively the "Parties".
        </Text>

        <Text style={styles.paragraph}>Whereas,</Text>

        <Text style={styles.listItem}>
          i. xMoney is the owner of the proprietary software and web-based
          platform (<Text style={styles.strong}>"Platform"</Text>) which enables
          its clients ("
          <Text style={styles.strong}>Merchant" or "Merchants")</Text> (a) to
          accept the payment for their own products and/or services in virtual
          currency, and also (b) to request any other entity and/or person a
          certain amount of virtual currency to be paid by such entity and/or
          person. The Merchants may receive those funds in their preferred
          currency, either in fiat currency into their bank account or in
          virtual currency to its corresponding address.
        </Text>
        <Text style={styles.listItem}>
          ii. Since xMoney only provides access to the web-based platform,
          xMoney resources to authorized third parties in order to ensure that
          the amounts paid by the Merchant's Customers are delivered to the
          Merchant in virtual or fiat currency.
        </Text>
        <Text style={styles.listItem}>
          iii. Therefore, xMoney uses its own software solutions in addition to
          incorporating or integrating third-party's payment services or
          licensing solutions.
        </Text>
        <Text style={styles.listItem}>
          iv. The corresponding virtual or fiat currency amount obtained will
          then be deposited into the Merchant's account ("the "Settlement").
        </Text>
        <Text style={styles.listItem}>
          v. Therefore, the Merchant wishes to engage the services of xMoney and
          onboard xMoney's gateway to enable its own customers (
          <Text style={styles.strong}>"Merchant's Customer" or "buyer"</Text>)
          to use virtual currencies as payment for services and products
          provided in its online shop or through a payment request ( "Invoice"),
          whilst receiving the due full amounts in fiat or virtual currency.
        </Text>
        <Text style={styles.listItem}>
          vi. The present agreement sets forth the terms that govern the
          provision and use of the xMoney Platform and its Services.
        </Text>

        <Text style={styles.paragraph}>
          The Parties hereby represent and warrant to each other that each of
          the statements is true and accurate in all respects unless otherwise
          specified hereafter.
        </Text>

        {merchantAgreementData({
          merchantFeePercentage,
          minSettlementAmount,
          minSettlementAmountInWritten,
          merchantEmail: email,
          businessAddress,
          date,
        }).map((section) => (
          <>
            <Text
              style={[styles.strong, styles.listTitle]}
              minPresenceAhead={96}
            >
              {section.title}
            </Text>
            {section.listItems && (
              <RecursiveListItems items={section.listItems} />
            )}
          </>
        ))}

        <View style={styles.signatureBlock}>
          <Text>Date: {date}</Text>
          <View style={styles.signatureRow}>
            <View style={styles.signatureColumn}>
              <Text>By and on behalf of Modernity Party OU:</Text>
              <Image style={styles.signatureImage} src={signature} />
              <Text>Roberto Manuel Dias Machado</Text>
            </View>
            <View style={styles.signatureColumn}>
              <Text>By and on behalf of the Merchant:</Text>
              <View style={styles.signatureLine} />
              <Text>
                {representativeName} on behalf of {companyOfficialName} as
                defined upon the account registration
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

MerchantServiceAgreementPDF.propTypes = {
  contractInfo: PropTypes.shape({
    companyOfficialName: PropTypes.string,
    country: PropTypes.string,
    date: PropTypes.string,
    email: PropTypes.string,
    representativeName: PropTypes.string,
  }),
};

export default MerchantServiceAgreementPDF;
