import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, StyleSheet } from '@react-pdf/renderer';

import { displayUtcDate } from 'common/services/date';

import { semiboldFont } from './pdfCommonStyles';

const styles = StyleSheet.create({
  table: {
    width: '100%',
    borderRadius: 8,
  },
  header: {
    flexDirection: 'row',
    backgroundColor: '#ECE5FF',
    borderBottomWidth: 1,
    borderBottomColor: '#E4E4E7',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#E4E4E7',
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: '#E4E4E7',
  },
  lastRow: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: '#E4E4E7',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  cell: {
    paddingVertical: 4,
    paddingHorizontal: 8,
  },
  headerText: {
    fontSize: 10,
    lineHeight: 1.2,
    fontFamily: semiboldFont,
  },
  cellText: {
    fontSize: 10,
    lineHeight: 1.2,
    color: '#252D3D',
  },
  tableTitle: {
    fontSize: 14,
    lineHeight: 1.14,
    fontFamily: semiboldFont,
    color: '#252D3D',
    marginBottom: 8,
    marginLeft: 8,
  },
  totalsRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 4,
  },
  totalCell: {
    paddingVertical: 4,
    paddingHorizontal: 8,
  },
  totalText: {
    fontSize: 10,
    lineHeight: 1.2,
    fontFamily: semiboldFont,
    color: '#252D3D',
    textAlign: 'right',
  },
});

export const columnWidths = {
  id: '5.81%',
  dateTime: '19.04%',
  reference: '14.23%',
  orderCurrency: '17.23%',
  referenceCurrency: '21.44%',
  settlementCurrency: '22.25%',
};

const getSettlementAmountWidth = (shouldShowReferenceCurrency) => {
  if (shouldShowReferenceCurrency) {
    return columnWidths.settlementCurrency;
  }

  const lastTwoColumnsWidths = Object.values(columnWidths)
    // referenceCurrency and settlementCurrency
    .slice(-2)
    // remove %
    .reduce((acc, curr) => acc + Number(curr.slice(0, -1)), 0);

  return `${lastTwoColumnsWidths}%`;
};

const TableHeader = ({ shouldShowReferenceCurrency }) => (
  <View style={styles.header} fixed>
    <View style={[styles.cell, { width: columnWidths.id }]}>
      <Text style={styles.headerText}>#</Text>
    </View>
    <View style={[styles.cell, { width: columnWidths.dateTime }]}>
      <Text style={styles.headerText}>Date & Time</Text>
    </View>
    <View style={[styles.cell, { width: columnWidths.reference }]}>
      <Text style={styles.headerText}>Reference</Text>
    </View>
    <View style={[styles.cell, { width: columnWidths.orderCurrency }]}>
      <Text style={[styles.headerText, { textAlign: 'right' }]}>
        Order currency
      </Text>
    </View>
    {shouldShowReferenceCurrency && (
      <View style={[styles.cell, { width: columnWidths.referenceCurrency }]}>
        <Text style={[styles.headerText, { textAlign: 'right' }]}>
          Reference currency
        </Text>
      </View>
    )}
    <View
      style={[
        styles.cell,
        {
          width: getSettlementAmountWidth(shouldShowReferenceCurrency),
        },
      ]}
    >
      <Text style={[styles.headerText, { textAlign: 'right' }]}>
        Settlement currency
      </Text>
    </View>
  </View>
);

const TableRow = ({ data, style, shouldShowReferenceCurrency }) => (
  // minPresenceAhead={10} is a workaround to fix the issue where the total row
  // is rendered on the next page
  <View style={[styles.row, style]} wrap={false} minPresenceAhead={10}>
    <View style={[styles.cell, { width: columnWidths.id }]}>
      <Text style={styles.cellText}>{data.id}</Text>
    </View>
    <View style={[styles.cell, { width: columnWidths.dateTime }]}>
      <Text style={styles.cellText}>{data.dateTime}</Text>
    </View>
    <View style={[styles.cell, { width: columnWidths.reference }]}>
      <Text style={styles.cellText}>{data.reference}</Text>
    </View>
    <View style={[styles.cell, { width: columnWidths.orderCurrency }]}>
      <Text style={[styles.cellText, { textAlign: 'right' }]}>
        {data.orderCurrency}
      </Text>
    </View>
    {shouldShowReferenceCurrency && (
      <View style={[styles.cell, { width: columnWidths.referenceCurrency }]}>
        <Text style={[styles.cellText, { textAlign: 'right' }]}>
          {data.referenceCurrency}
        </Text>
      </View>
    )}
    <View
      style={[
        styles.cell,
        {
          width: getSettlementAmountWidth(shouldShowReferenceCurrency),
        },
      ]}
    >
      <Text style={[styles.cellText, { textAlign: 'right' }]}>
        {data.settlementCurrency}
      </Text>
    </View>
  </View>
);

const TotalsRow = ({
  amountLabel,
  amount,
  referenceAmount,
  shouldShowReferenceCurrency,
}) => (
  <View style={styles.totalsRow} wrap={false}>
    <View
      style={[
        styles.totalCell,
        {
          width: `${
            parseFloat(columnWidths.id) +
            parseFloat(columnWidths.dateTime) +
            parseFloat(columnWidths.reference) +
            parseFloat(columnWidths.orderCurrency)
          }%`,
        },
      ]}
    >
      <Text style={[styles.totalText, { textAlign: 'right' }]}>
        {amountLabel}
      </Text>
    </View>
    {shouldShowReferenceCurrency && (
      <View
        style={[
          styles.totalCell,
          {
            width: columnWidths.referenceCurrency,
          },
        ]}
      >
        <Text style={styles.totalText}>{referenceAmount}</Text>
      </View>
    )}
    <View
      style={[
        styles.totalCell,
        {
          width: getSettlementAmountWidth(shouldShowReferenceCurrency),
        },
      ]}
    >
      <Text style={styles.totalText}>{amount}</Text>
    </View>
  </View>
);

/**
 * confirmedAt -> orders
 * paidAt -> invoices
 * createdAt -> refunds or legacy orders/invoices
 */
const dateAndTime = (dateFields) =>
  displayUtcDate(
    dateFields?.confirmedAt || dateFields?.paidAt || dateFields?.createdAt,
    'yyyy/MM/dd HH:mm'
  );

export const ReportTable = ({
  data,
  title,
  total,
  totalReference,
  totalTitle,
  shouldShowReferenceCurrency,
  referenceCurrency,
  settlementCurrency,
  emptyMessage,
}) => {
  const transformedData = data.map((item, index) => ({
    id: (index + 1).toString().padStart(2, '0'),
    dateTime: dateAndTime(item),
    reference: item.reference,
    orderCurrency: `${item.amount} ${item.currency}`,
    referenceCurrency: `${item.referenceFiatAmount} ${referenceCurrency}`,
    settlementCurrency: `${item.settlementAmount} ${settlementCurrency}`,
  }));

  return (
    <View style={{ marginTop: 16 }}>
      <Text style={styles.tableTitle} fixed>
        {title}
      </Text>
      <View style={styles.table}>
        <TableHeader
          shouldShowReferenceCurrency={shouldShowReferenceCurrency}
        />
        {transformedData.length > 0 ? (
          <>
            {transformedData.map((row, index) => (
              <TableRow
                key={row.reference}
                data={row}
                shouldShowReferenceCurrency={shouldShowReferenceCurrency}
                style={
                  index === transformedData.length - 1 ? styles.lastRow : null
                }
              />
            ))}
            {total && (
              <TotalsRow
                amountLabel={totalTitle}
                amount={`${total.value} ${settlementCurrency}`}
                referenceAmount={`${totalReference} ${referenceCurrency}`}
                shouldShowReferenceCurrency={shouldShowReferenceCurrency}
              />
            )}
          </>
        ) : (
          <View style={styles.lastRow}>
            <View style={[styles.cell, { flex: 1 }]}>
              <Text style={styles.cellText}>{emptyMessage}</Text>
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

ReportTable.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.string,
      createdAt: PropTypes.string,
      reference: PropTypes.string,
      utrustFeeMultiplier: PropTypes.string,
      utrustFeeTotal: PropTypes.string,
      utrustFeeVat: PropTypes.string,
      utrustFeeVatAmount: PropTypes.string,
      totalPaid: PropTypes.string,
    })
  ),
  total: PropTypes.shape({
    value: PropTypes.string.isRequired,
    currency: PropTypes.string,
  }),
  totalReference: PropTypes.string,
  totalTitle: PropTypes.string,
  shouldShowReferenceCurrency: PropTypes.bool.isRequired,
  referenceCurrency: PropTypes.string,
  settlementCurrency: PropTypes.string,
  emptyMessage: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      justify: PropTypes.string,
    })
  ).isRequired,
};

ReportTable.defaultProps = {
  data: null,
  total: null,
  totalTitle: null,
};
