import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { reduce, find, remove } from 'lodash';
import { PDFDownloadLink } from '@react-pdf/renderer';

import { selectors as featuresSelectors } from 'features';
import Heading from 'common/components/Heading';
import Separator from 'common/components/Separator';
import ViewProperty from 'common/components/ViewProperty';
import timezones from 'common/timezones';
import Icon from 'common/components/Icon';
import styles from './index.module.css';
import MerchantServiceAgreementPDF from './MerchantServiceAgreementPDF';

const labels = {
  referenceFiatCurrency: 'Reference currency',
  name: 'Merchant name',
  url: 'Website URL',
  companyOfficialName: 'Legal name',
  sector: 'Sector',
  registeredAddress: 'Legal address',
  country: 'Country',
  timezone: 'Timezone',
  vatNumber: 'VAT Number',
};

const Business = ({
  name,
  url,
  contractInfo,
  enableContractDownload,
  ...props
}) => {
  const isReferenceCurrencyActive = useSelector(
    featuresSelectors.isReferenceCurrencyActive
  );

  const companyFields = reduce(
    labels,
    (result, value, key) => {
      const propValue = props[key];

      if (propValue) {
        result.push({
          label: value,
          value:
            key === 'timezone'
              ? find(timezones, { value: propValue })?.label
              : propValue,
          key,
        });
      }
      return result;
    },
    []
  );

  if (props.referenceFiatCurrency && !isReferenceCurrencyActive) {
    remove(companyFields, (n) => n.key === 'referenceFiatCurrency');
  }

  const PDFContractInfo = {
    ...contractInfo,
    companyOfficialName: props.companyOfficialName,
  };

  return (
    <div className={styles.root}>
      <Heading level="3" size="3" weight="medium" id={'store-information'}>
        Merchant information
      </Heading>
      <div className={styles.store}>
        <ViewProperty label={labels.name} value={name} />
        <ViewProperty label={labels.url} value={url} />
      </div>
      <div className={styles.separator}>
        <Separator />
      </div>
      <Heading level="3" size="3" weight="medium" id={'company-information'}>
        Company information
      </Heading>
      <div className={styles.company}>
        {companyFields.map(({ label, value }) => (
          <ViewProperty label={label} value={value} key={label} />
        ))}
      </div>
      {enableContractDownload && (
        <>
          <div className={styles.separator}>
            <Separator />
          </div>
          <Heading level="3" size="3" weight="medium" id={'documents'}>
            Documents
          </Heading>
          <div className={styles.documents}>
            <PDFDownloadLink
              document={
                <MerchantServiceAgreementPDF contractInfo={PDFContractInfo} />
              }
              fileName={`xMoney-Crypto-Contract-${props.companyOfficialName}-${contractInfo.date}.pdf`}
              className={styles.downloadButton}
            >
              {({ loading, error }) => (
                <>
                  {error ? (
                    <span>Error generating PDF</span>
                  ) : (
                    <>
                      <Icon.Download
                        className={styles.downloadButtonIcon}
                        description="Download icon"
                        size="xSmall"
                      />
                      <span>
                        {loading
                          ? 'Generating PDF...'
                          : 'Merchant service agreement'}
                      </span>
                    </>
                  )}
                </>
              )}
            </PDFDownloadLink>
          </div>
        </>
      )}
    </div>
  );
};

Business.propTypes = {
  companyOfficialName: PropTypes.string,
  country: PropTypes.string,
  name: PropTypes.string,
  registeredAddress: PropTypes.string,
  sectorId: PropTypes.number,
  settlementCurrency: PropTypes.string,
  timezone: PropTypes.string,
  url: PropTypes.string,
  vatNumber: PropTypes.string,
  enableContractDownload: PropTypes.bool,
  contractInfo: PropTypes.shape({
    business_address: PropTypes.string,
    business_name: PropTypes.string,
    company_number: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.oneOf([null]),
    ]),
    country: PropTypes.string,
    date: PropTypes.string,
    email: PropTypes.string,
    merchant_uuid: PropTypes.string,
  }),
};

export default Business;
