import { Font, StyleSheet } from '@react-pdf/renderer';

import RoobertProRegular from '../../../common/fonts/RoobertPRO-Regular.woff';
import RoobertProSemiBold from '../../../common/fonts/RoobertPRO-SemiBold.woff';

export const regularFont = 'Roobert Pro';
export const semiboldFont = 'Roobert Pro Semibold';

Font.register({
  family: 'Roobert Pro',
  src: RoobertProRegular,
  fontWeight: 'normal',
  fontStyle: 'normal',
});

Font.register({
  family: 'Roobert Pro Semibold',
  src: RoobertProSemiBold,
  fontWeight: 'semibold',
  fontStyle: 'normal',
});

export const commonStyles = StyleSheet.create({
  page: {
    padding: 48,
    paddingBottom: 80,
    fontFamily: regularFont,
    color: '#252D3D',
  },
  semibold: {
    fontFamily: semiboldFont,
  },
});
