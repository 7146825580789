import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePDF } from '@react-pdf/renderer';

import { useOnMountDataRequest } from 'common/hooks';
import { selectors as authSelectors } from 'auth';
import {
  isFetchingReport as isFetchingReportSelector,
  report as reportSelector,
} from 'settlements/reducers';

import { activeStore as getActiveStore } from 'session/reducers';
import actions from 'settlements/actions';
import { managePermission } from 'payments/propTypes';
import ReportPDF from 'settlements/components/ReportPDF';
import Text from 'common/components/Text';
import Icon from 'common/components/Icon';
import { displayUtcDate } from 'common/services/date';

import styles from './index.module.css';

const useReportData = (id) => {
  const { isMounting } = useOnMountDataRequest(
    actions.singleSettlementRequest,
    id
  );
  const isFetchingReport = useSelector(isFetchingReportSelector);
  const report = useSelector(reportSelector);
  const activeStore = useSelector(getActiveStore);
  const permissions = useSelector(authSelectors.getPermissions);

  const isInvoicingEnabled = permissions?.invoicing === managePermission;
  const isOnrampEnabled = permissions?.onramp === managePermission;

  return {
    activeStore,
    isFetching: isMounting || isFetchingReport,
    isInvoicingEnabled,
    isOnrampEnabled,
    report,
  };
};

const Button = ({ children, ...props }) => (
  <button className={styles.button} type="button" {...props}>
    {children}
  </button>
);

const DownloadPDF = ({ id, onSucess }) => {
  const {
    activeStore: { name, companyOfficialName },
    isFetching,
    isInvoicingEnabled,
    isOnrampEnabled,
    report,
  } = useReportData(id);

  const [instance, updateDocument] = usePDF();

  const documentName = report
    ? `SettlementReport-${displayUtcDate(
        report?.startsAt,
        'yyyy-MM-dd'
      )}_${displayUtcDate(report?.endsAt, 'yyyy-MM-dd')}.pdf`
    : 'SettlementReport.pdf';

  useEffect(() => {
    if (instance.url) {
      const link = document.createElement('a');
      link.href = instance.url;
      link.download = documentName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      onSucess();
    }
  }, [instance.url]);

  if (isFetching || instance.loading)
    return <Button disabled>Creating PDF...</Button>;

  if (instance.error) {
    return <Button disabled>Error creating PDF</Button>;
  }

  if (!instance.blob) {
    updateDocument(
      <ReportPDF
        activeStoreName={name}
        companyOfficialName={companyOfficialName}
        isInvoicingEnabled={isInvoicingEnabled}
        isOnrampEnabled={isOnrampEnabled}
        data={report}
      />
    );
  }

  return null;
};

export const DownloadReportButton = ({ id }) => {
  const [isClicked, setIsClicked] = useState(false);

  const onSucess = () => {
    setIsClicked(false);
  };

  const onClick = () => {
    if (isClicked) return;

    setIsClicked(true);
  };

  if (isClicked) return <DownloadPDF id={id} onSucess={onSucess} />;

  return (
    <Button type="button" onClick={onClick}>
      <Text>PDF</Text>
      <Icon.Download size="medium" />
    </Button>
  );
};
