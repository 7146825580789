import React from 'react';
import PropTypes from 'prop-types';
import {
  Document,
  Page,
  Text,
  View,
  Link,
  StyleSheet,
  Font,
} from '@react-pdf/renderer';
import { getCountryLabel } from 'common/countryHelpers';
import { utcDateFormatter } from 'common/services/date';
import {
  cancelledStatus,
  expiredStatus,
  invoicePropType,
  paidStatus,
  pendingStatus,
} from 'payments/propTypes';
import {
  isCrypto,
  cryptoValueWithCurrency,
  fiatValueWithCurrency,
  roundFiat,
} from 'common/currencies';
import { countriesPropType } from 'common/propTypes';
import { invoiceStatuses } from 'payments/translations';
import { PdfFooterLogo } from 'common/components/PDF/PdfFooterLogo';

import RoobertProRegular from '../../../common/fonts/RoobertPRO-Regular.woff';
import RoobertProSemiBold from '../../../common/fonts/RoobertPRO-SemiBold.woff';

Font.register({
  family: 'Roobert Pro',
  src: RoobertProRegular,
  fontWeight: 'normal',
  fontStyle: 'normal',
});

Font.register({
  family: 'Roobert Pro Semibold',
  src: RoobertProSemiBold,
  fontWeight: 'semibold',
  fontStyle: 'normal',
});

const styles = StyleSheet.create({
  page: {
    padding: 48,
    fontFamily: 'Roobert Pro',
  },
  title: {
    fontSize: 28,
    lineHeight: 1.29,
    fontFamily: 'Roobert Pro Semibold',
  },
  lineItems: {
    flexDirection: 'column',
    marginTop: 8,
    gap: 8,
  },
  lineItem: {
    flexDirection: 'row',
  },
  lineItemLabel: {
    color: '#252D3D',
    fontSize: 14,
    lineHeight: 1.14,
    marginRight: 4,
  },
  content: {
    color: '#7C818B',
    fontSize: 14,
    lineHeight: 1.14,
  },
  descriptionContent: {
    color: '#7C818B',
    fontSize: 14,
    lineHeight: 1.14,
    marginTop: 4,
  },
  label: {
    color: '#252D3D',
    fontSize: 14,
    lineHeight: 1.14,
    fontFamily: 'Roobert Pro Semibold',
  },
  separator: {
    borderBottomWidth: 1,
    borderBottomColor: '#EEEEEE',
    marginVertical: 16,
  },
  columnItem: {
    flex: 1,
    flexDirection: 'column',
    gap: 4,
  },
  userDetails: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 16,
  },
  paymentDetails: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  amount: {
    marginLeft: 'auto',
    textAlign: 'right',
  },
  amountValue: {
    fontFamily: 'Roobert Pro Semibold',
    fontSize: 20,
    lineHeight: 1.4,
    color: '#252D3D',
    marginTop: 4,
  },
  link: {
    color: '#ffffff',
    textDecoration: 'none',
    padding: 8,
    borderRadius: 2,
    backgroundColor: '#7C4DFF',
    fontSize: 14,
    lineHeight: 1.14,
  },
});

const LineItem = ({ label, value }) => (
  <View style={styles.lineItem}>
    <Text style={styles.lineItemLabel}>{label}</Text>
    <Text style={styles.content}>{value}</Text>
  </View>
);

const InvoiceReport = ({
  businessInfo: { companyOfficialName },
  buyerCountries,
  invoice: {
    createdAt,
    customer: {
      name,
      email,
      billingAddress,
      postCode,
      city,
      state,
      country: customerCountry,
    },
    paidAt,
    description,
    reference,
    totalAmount,
    paymentUrl,
    status,
  },
}) => {
  const getAmountLabel = () => {
    if (status === cancelledStatus || status === expiredStatus) {
      return 'Total amount';
    }

    return status === pendingStatus ? 'Amount due' : 'Amount paid';
  };

  const formatDate = (date) => {
    const displayUtcDate = utcDateFormatter(date);
    return displayUtcDate('MMMM d, yyyy');
  };

  return (
    <Document>
      <Page size="A4" style={styles.page} break={false}>
        <Text style={styles.title}>
          {status === paidStatus ? 'Receipt' : 'Invoice'} -{' '}
          {companyOfficialName}
        </Text>

        <View style={styles.lineItems}>
          <LineItem label="Reference number:" value={reference} />
          <LineItem label="Issue date:" value={formatDate(createdAt)} />
          <LineItem label="Status:" value={invoiceStatuses[status]} />
          {paidAt && (
            <LineItem label="Payment date:" value={formatDate(paidAt)} />
          )}
        </View>

        <View style={styles.separator} />

        <View style={styles.userDetails}>
          <View style={styles.columnItem}>
            <Text style={styles.label}>Billed to</Text>
            <Text style={styles.content}>{name}</Text>
            <Text style={styles.content}>{email}</Text>
          </View>

          <View style={styles.columnItem}>
            <Text style={styles.label}>Billing address</Text>
            <Text style={styles.content}>
              {`${billingAddress}, ${postCode} ${city}${
                state ? `, ${state}` : ''
              }, ${getCountryLabel(buyerCountries, customerCountry)}`}
            </Text>
          </View>
        </View>

        <View style={styles.separator} />

        <View>
          <Text style={styles.label}>Description</Text>
          <Text style={styles.descriptionContent}>{description}</Text>
        </View>

        <View style={styles.separator} />

        <View style={styles.paymentDetails}>
          {status === pendingStatus && (
            <Link href={paymentUrl} style={styles.link}>
              Pay with crypto
            </Link>
          )}
          <View style={styles.amount}>
            <Text style={styles.label}>{getAmountLabel()}</Text>
            <Text style={styles.amountValue}>
              {isCrypto(totalAmount.currency)
                ? cryptoValueWithCurrency(
                    totalAmount.value,
                    totalAmount.currency
                  )
                : fiatValueWithCurrency(
                    roundFiat(totalAmount.value),
                    totalAmount.currency
                  )}
            </Text>
          </View>
        </View>
        <View style={{ position: 'absolute', bottom: 48, left: 48 }}>
          <PdfFooterLogo />
        </View>
      </Page>
    </Document>
  );
};

InvoiceReport.propTypes = {
  businessInfo: PropTypes.shape({
    companyOfficialName: PropTypes.string,
    country: PropTypes.string,
    registeredAddress: PropTypes.string,
  }).isRequired,
  merchantCountries: countriesPropType,
  buyerCountries: countriesPropType,
  invoice: invoicePropType.isRequired,
};

export default InvoiceReport;
