import React from 'react';
import { filter, first, chain } from 'lodash';
import { useSelector } from 'react-redux';

import { useCompanyInfo, useContract } from 'common/hooks';
import LoadingPage from 'common/components/LoadingPage';
import FadeInTransition from 'common/components/FadeInTransition';
import { fetchContractInfo, fetchBusinessDetails } from 'app/services/api';
import { selectors as sessionSelectors } from 'session';
import ErrorPage from '../components/ErrorPage';
import Business from '../components/Business';

const normalizeAttributesResponse = (response) => {
  const normalized = chain(response)
    .values()
    .map((item) => item.attributes)
    .value();
  return normalized[0];
};

const useBusinessData = () => {
  const [contractInfo, setContractInfo] = React.useState(null);
  const [businessDetails, setBusinessDetails] = React.useState(null);
  const [isLoadingContractInfo, setIsLoadingContractInfo] =
    React.useState(true);
  const [isLoadingBusinessDetails, setIsLoadingBusinessDetails] =
    React.useState(true);
  const activeOrganization = useSelector(sessionSelectors.activeOrganization);

  React.useEffect(() => {
    const fetchData = async () => {
      if (activeOrganization?.id) {
        const [contractResponse, businessDetailsResponse] = await Promise.all([
          fetchContractInfo(activeOrganization.id),
          fetchBusinessDetails(),
        ]);

        if (!contractResponse.error) {
          setContractInfo(
            normalizeAttributesResponse(
              contractResponse.response.merchantContractInfo
            )
          );
        }
        setIsLoadingContractInfo(false);

        if (!businessDetailsResponse.error) {
          setBusinessDetails(
            normalizeAttributesResponse(
              businessDetailsResponse.response.businessDetails
            )
          );
        }
        setIsLoadingBusinessDetails(false);
      }
    };
    fetchData();
  }, [activeOrganization]);

  return {
    contractInfo,
    businessDetails,
    isLoading: isLoadingContractInfo || isLoadingBusinessDetails,
  };
};

const BusinessContainer = () => {
  const { contractInfo, businessDetails, isLoading } = useBusinessData();
  const { errorMessage, sectors, countries, businessInfo } = useCompanyInfo({
    withCountries: true,
    withSectors: true,
  });
  const [contract, isLoadingContract] = useContract();

  if (errorMessage) return <ErrorPage />;
  if (!sectors || !businessInfo || isLoadingContract || isLoading)
    return <LoadingPage />;

  const { sectorId, country, ...businessProps } = businessInfo;

  const sectorLookup = Object.entries(sectors.sectorsByCategory).reduce(
    (acc, [category, sectorList]) => {
      sectorList.forEach((sector) => {
        acc[sector.value] = {
          subCategory: sector.label,
          value: sector.value,
          category: category !== 'Sectors' ? category : null,
        };
      });
      return acc;
    },
    {}
  );

  const merchantSector = sectorId ? sectorLookup[sectorId] : null;
  const sectorLabel = merchantSector
    ? `${merchantSector.category ? `${merchantSector.category} - ` : ''}${
        merchantSector.subCategory
      }`
    : '';

  const countryObject = first(filter(countries, { value: country }));
  const countryLabel = countryObject?.label || country;

  return (
    <FadeInTransition>
      <Business
        {...businessProps}
        sector={sectorLabel}
        country={countryLabel}
        referenceFiatCurrency={contract?.referenceFiatCurrency}
        contractInfo={contractInfo}
        enableContractDownload={businessDetails?.enableContractDownload}
      />
    </FadeInTransition>
  );
};

export default BusinessContainer;
