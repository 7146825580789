import React from 'react';
import PropTypes from 'prop-types';
import { Document, Page, Text, View } from '@react-pdf/renderer';

import { settlement as settlementPropType } from 'settlements/propTypes';
import { getTitlePrefix as getTitlePrefixBase } from 'settlements/utils/getTitlePrefix';
import { PdfFooterLogo } from 'common/components/PDF/PdfFooterLogo';

import { ReportTable } from './ReportTable';
import { commonStyles } from './pdfCommonStyles';
import { ReportHeader } from './ReportHeader';
import ReportsTotals from './ReportsTotals';

const Report = ({
  activeStoreName,
  companyOfficialName,
  isInvoicingEnabled,
  isOnrampEnabled,
  data: {
    endsAt,
    fees,
    paidInvoices,
    paidOrders,
    paidFiatBills,
    referenceFees,
    referenceFiatCurrency,
    referenceToBeSettledAmount,
    referenceTotalAmount,
    referenceTotalInvoicesAmount,
    referenceTotalOrdersAmount,
    referenceTotalRefundedAmount,
    referenceTotalFiatBillsAmount,
    settlementBlockchain,
    refundedOrders,
    settledOn,
    shortId: id,
    startsAt,
    toBeSettledAmount,
    totalAmount,
    totalInvoicesAmount,
    totalOrdersAmount,
    totalRefundedAmount,
    totalFiatBillsAmount,
  },
}) => {
  const shouldShowReferenceCurrency = !!referenceFiatCurrency;
  const settlementCurrency = toBeSettledAmount.currency;

  const getTitlePrefix = (section) =>
    getTitlePrefixBase(section, { isInvoicingEnabled, isOnrampEnabled });

  return (
    <Document>
      <Page size="A4" style={commonStyles.page}>
        <ReportHeader
          id={id}
          activeStoreName={activeStoreName}
          companyOfficialName={companyOfficialName}
          start={startsAt}
          end={endsAt}
          settledOn={settledOn}
          blockchain={settlementBlockchain}
          toBeSettledAmount={toBeSettledAmount}
          toBeSettledReferenceAmount={referenceToBeSettledAmount}
          referenceCurrency={referenceFiatCurrency}
        />
        <ReportTable
          id="ordersPaid"
          title={`${getTitlePrefix('order')}. Orders paid`}
          data={paidOrders}
          total={totalOrdersAmount}
          totalReference={referenceTotalOrdersAmount}
          totalTitle="Total orders paid"
          emptyMessage="No orders"
          referenceCurrency={referenceFiatCurrency}
          settlementCurrency={settlementCurrency}
          shouldShowReferenceCurrency={shouldShowReferenceCurrency}
        />
        {isInvoicingEnabled && (
          <ReportTable
            id="invoicesPaid"
            title={`${getTitlePrefix('invoice')}. Invoices paid`}
            data={paidInvoices}
            total={totalInvoicesAmount}
            totalReference={referenceTotalInvoicesAmount}
            totalTitle="Total invoices paid"
            emptyMessage="No invoices"
            referenceCurrency={referenceFiatCurrency}
            settlementCurrency={settlementCurrency}
            shouldShowReferenceCurrency={shouldShowReferenceCurrency}
          />
        )}
        <ReportTable
          id="refundsPaid"
          title={`${getTitlePrefix('refund')}. Orders refunded`}
          data={refundedOrders}
          total={totalRefundedAmount}
          totalReference={referenceTotalRefundedAmount}
          totalTitle="Total orders refunded"
          emptyMessage="No refunds"
          referenceCurrency={referenceFiatCurrency}
          settlementCurrency={settlementCurrency}
          shouldShowReferenceCurrency={shouldShowReferenceCurrency}
        />
        {isOnrampEnabled && (
          <ReportTable
            id="fiatBillsPaid"
            title={`${getTitlePrefix('fiatBill')}. Onramp`}
            data={paidFiatBills}
            total={totalFiatBillsAmount}
            totalReference={referenceTotalFiatBillsAmount}
            totalTitle="Total onramp"
            emptyMessage="No onramp"
            referenceCurrency={referenceFiatCurrency}
            settlementCurrency={settlementCurrency}
            shouldShowReferenceCurrency={shouldShowReferenceCurrency}
          />
        )}
        <ReportsTotals
          fees={fees}
          isInvoicingEnabled={isInvoicingEnabled}
          isOnrampEnabled={isOnrampEnabled}
          referenceCurrency={referenceFiatCurrency}
          referenceFees={referenceFees}
          shouldShowReferenceCurrency={shouldShowReferenceCurrency}
          toBeSettledAmount={toBeSettledAmount}
          toBeSettledReferenceAmount={referenceToBeSettledAmount}
          totalAmount={totalAmount}
          totalInvoicesAmount={totalInvoicesAmount}
          totalFiatBillsAmount={totalFiatBillsAmount}
          totalOrdersAmount={totalOrdersAmount}
          totalReferenceAmount={referenceTotalAmount}
          totalReferenceInvoicesAmount={referenceTotalInvoicesAmount}
          totalReferenceOrdersAmount={referenceTotalOrdersAmount}
          totalReferenceRefundedAmount={referenceTotalRefundedAmount}
          totalReferenceFiatBillsAmount={referenceTotalFiatBillsAmount}
          totalRefundedAmount={totalRefundedAmount}
        />

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            position: 'absolute',
            bottom: 48,
            left: 56,
            right: 56,
          }}
        >
          <PdfFooterLogo />
          <View
            render={({ pageNumber, totalPages }) => (
              <Text style={{ fontSize: 10, lineHeight: 1.2, color: '#92969E' }}>
                Page {pageNumber} of {totalPages}
              </Text>
            )}
          />
        </View>
      </Page>
    </Document>
  );
};

Report.propTypes = {
  data: settlementPropType.isRequired,
  activeStoreName: PropTypes.string.isRequired,
  companyOfficialName: PropTypes.string.isRequired,
  isInvoicingEnabled: PropTypes.bool.isRequired,
  isOnrampEnabled: PropTypes.bool.isRequired,
};

export default Report;
