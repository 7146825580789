const DIGIT_MAP = {
  ones: [
    '',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
  ],
  tens: [
    '',
    '',
    'twenty',
    'thirty',
    'forty',
    'fifty',
    'sixty',
    'seventy',
    'eighty',
    'ninety',
  ],
  teens: [
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
  ],
  scales: ['', 'thousand', 'million'],
};

export const numberToWords = (num) => {
  if (!Number.isInteger(num)) throw new Error('Input must be an integer');
  if (num < 0) throw new Error('Input must be a non-negative integer');
  if (num === 0) return 'zero';

  const processChunk = (chunk) => {
    if (chunk === 0) return '';

    const hundreds = Math.floor(chunk / 100);
    const remainder = chunk % 100;

    let result =
      hundreds > 0
        ? `${DIGIT_MAP.ones[hundreds]} hundred${remainder ? ' ' : ''}`
        : '';

    if (remainder) {
      if (remainder < 10) result += DIGIT_MAP.ones[remainder];
      else if (remainder < 20) result += DIGIT_MAP.teens[remainder - 10];
      else {
        const [tens, ones] = [Math.floor(remainder / 10), remainder % 10];
        result +=
          DIGIT_MAP.tens[tens] + (ones ? `-${DIGIT_MAP.ones[ones]}` : '');
      }
    }

    return result;
  };

  return Array.from({ length: Math.ceil(Math.log10(num + 1) / 3) }, (_, i) => {
    if (i >= DIGIT_MAP.scales.length) return '';
    const chunk = Math.floor(num / 1000 ** i) % 1000;
    return chunk ? `${processChunk(chunk)} ${DIGIT_MAP.scales[i]}`.trim() : '';
  })
    .reverse()
    .filter(Boolean)
    .join(' ')
    .trim();
};
